.Layout-header {
    padding: 10px 20px;
    border-bottom: 1px solid #aaa;

    b {
        float: right;
    }


}

.Layout-back {
    padding: 10px 20px;
    border-bottom: 1px solid #aaa;
    cursor: pointer;
    background-color: rgba(170, 170, 170, 0.555);

    &:hover {}


}

.Layout-section-switch {
    background: #eee;
    display: flex;
    margin: 20px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    overflow: hidden;

    b {
        padding: 8px 0;
        flex: 1;
        cursor: pointer;
        text-align: center;
        font-weight: normal;
        font-size: 13px;

    }

    .Layout-section-switch-active {
        background: #222;
        color: #fff;
    }
}


.Layout-slide-left {
    // animation-duration: 1s;

    // animation-fill-mode: both;
    // animation-name: LayoutSlideLeft;
    // animation-timing-function: cubic-bezier(.17, .67, .04, .99)
}

@keyframes LayoutSlideLeft {
    from {
        transform: translateX(80px) translate3d(0%, 0%, 0) skewX(0deg);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}


.Layout-add {
    margin: 5px;
    height: 40px;
    width: 40px;
    background: rgb(228, 228, 228);
    color: #000;
    line-height: 40px;
    text-align: center;
    border-radius: 4px;
    margin-left: auto;
    cursor: pointer;

    &:hover {
        background: rgb(170, 170, 170);
    }
}

.Layout {
    .Layout-counter {
        padding: 15px 15px 10px 15px;
    }


    .Layout-line {
        cursor: pointer;
        border-bottom: 1px solid rgb(218, 218, 218);



        .Layout-line-title {
            display: flex;
            padding: 5px;
            align-items: center;
            gap: 10px;

            b {
                font-size: 10px;
                padding: 5px 10px;
                text-transform: uppercase;
                font-weight: 400;
                border-radius: 4px;

            }

            .Layout-line-title-icon {
                background: #000;
                height: 40px;
                width: 40px;
                color: #fff;
                font-size: 20px;
                line-height: 40px;
                text-align: center;
                border-radius: 4px;
            }

            .Layout-line-title-text {
                color: #000;
                flex: 1;
            }

            .Layout-line-title-buttons {
                display: flex;
                align-items: center;
                gap: 5px;

                .Layout-line-title-buttons-button {
                    width: 40px;
                    background: #eee;
                    height: 40px;
                    border-radius: 5px;
                    line-height: 40px;
                    text-align: center;
                    display: block;

                    &:hover {
                        background: #000;
                        color: #fff;
                    }
                }
            }
        }
    }

}