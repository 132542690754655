body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}


body,
html,
:root {
    background-color: #e4e4e4;
    --radius: 10px;
    --shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

}

body {
    overflow-y: scroll;
}

.App-content {
    position: relative;
    margin: 2% 5%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 40px;
}

iframe {
    height: 100%;
    width: 100%;
    min-height: 600px;
    border: none;
    background: transparent;
}

.iframe {
    min-height: 800px;
    display: block;
    flex: 1;
    padding: 0px;
    bottom: 0px;
    overflow: hidden;
    border: none;
    background-color: transparent;
}

.App iframe {

    flex: 1;
}

.Page,
.Bar {

    box-sizing: border-box;

    color: #000;
    position: relative;

}

.App-width-small .iframe {
    max-width: 500px;
}

.Window-controls {
    padding: 5% 5% 0;
    overflow: hidden;
}

.Bar {

    display: flex;
    flex-direction: column;
    padding: 0px;
    width: 400px;
    display: flex;
    max-width: 400px;
    min-width: 400px;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;

}

.Bar-hide {
    gap: 0;
}

.Bar-hide .Page {
    margin-left: -400px;


    animation-duration: 1s;

    animation-fill-mode: both;
    animation-name: PageShowExpand;
    animation-timing-function: cubic-bezier(.17, .67, .04, .99)
}

.Bar-hide .Bar {
    margin-top: -50px;
}

.Bar-hide .Bar-content {
    display: none;

}

.Bar-Scroll {
    flex: 1;
}

.Back {
    background: #000;
    color: #fff;
    height: 50px;
    width: 50px;
    line-height: 50px;
    margin: 20px;
    text-align: center;
    border-radius: 100px;
}

.List {
    color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

}

.List-item {
    height: 50px;
    align-items: center;
    display: flex;
    cursor: pointer;
    color: #000;
    background-color: #eee;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.List-item:last-child {
    border-bottom: none;
}

.List-item:hover {
    background: rgba(0, 0, 0, 0.3);
}

.List-item b {
    padding-left: 20px;
}

.List-item i {
    margin-left: auto;
    height: 40px;
    margin-right: 10px;
    border-radius: 100px;
    text-align: center;
    line-height: 40px;
    color: rgb(0, 0, 0);
    width: 40px;
}

.List-title {
    padding: 10px 15px;
    position: sticky;
    top: 0;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 2px;
    color: #000;
}




*::-webkit-scrollbar {
    width: 15px;
}

*::-webkit-scrollbar-thumb {
    background: #333;
}

*::-webkit-scrollbar-track {
    background: #666;
}

*::-webkit-scrollbar-button {
    background: #333;
    height: 0;
    width: 0;
    border-radius: 50%;
}

.btn-info {
    background-color: rgb(31, 31, 31);
    color: rgba(255, 255, 255, 0.891);
    border: none;
    font-weight: bold;
    padding: 10px 20px;
    cursor: pointer;
    text-align: center;
    float: right;
    text-transform: uppercase;
    letter-spacing: 3px;
    border-radius: 5px;
    font-size: 15px;
}

.Page {
    flex: 1;
}





@keyframes load-infinite-a {
    0% {
        opacity: 1;
        transform: translate(-100%, 0px);
    }

    75% {
        opacity: 1;
        transform: translate(300%, 0px);
    }

    75.01% {
        opacity: 0;
    }

    100% {
        opacity: 0;
        /* temp hold value */
        transform: translate(300%, 0px);
        /* temp hold value */
    }
}

@keyframes load-infinite-b {
    0% {
        opacity: 0;
        /* temp hold value */
        transform: translate(-150%, 0px);
        /* temp hold value */
    }

    24.99% {
        opacity: 0;
    }

    25% {
        opacity: 1;
        transform: translate(-150%, 0px);
    }

    100% {
        transform: translate(166.66%, 0px);
        opacity: 1;
    }
}


.Bar-content {
    border-radius: var(--radius);
    background: #fff;
    box-shadow: var(--shadow);
    overflow: hidden;
    background-color: #fff;

    animation-duration: 1s;

    animation-fill-mode: both;
    animation-name: MenuShow;
    animation-timing-function: cubic-bezier(.17, .67, .04, .99)
}

@keyframes MenuShow {
    from {
        transform: translateY(-300px) translate3d(-10%, 0%, 0) skewX(0deg);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}


.Page {


    animation-duration: 1s;

    animation-fill-mode: both;
    animation-name: PageShow;
    animation-timing-function: cubic-bezier(.17, .67, .04, .99)
}

@keyframes PageShow {
    from {
        transform: translateY(300px) translate3d(0%, 0%, 0) skewX(0deg);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes PageShowExpand {
    from {
        transform: translateY(300px) translate3d(0%, 0%, 0) skewX(0deg);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}







.submits {
    margin-top: 20px;
    background: #eee;
    margin-bottom: -20px;
    margin-left: -20px;
    margin-right: -20px;
    padding: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    overflow: hidden;
}