.Layout-icon {

    .Layour-icon-search {
        display: flex;

        input {
            line-height: 40px;
            flex: 1;
            background: rgba(0, 0, 0, 0.384);
            border: none;
            border-radius: 3px;
            margin-bottom: 20px;
            text-indent: 15px;
            font-size: 14px;
            color: #fff;
            outline: none;
        }
    }

    .Layour-icon-list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        align-items: center;
        justify-content: center;
        max-height: 400px;
        overflow-y: scroll;



        &::-webkit-scrollbar {
            width: 5px;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background: rgb(255, 255, 255);
            border-radius: 5px;

        }

        &::-webkit-scrollbar-track {
            background: rgb(0, 0, 0);
            border-radius: 5px;

        }

        &::-webkit-scrollbar-button {
            background: #333;
            height: 0;
            width: 0;
            border-radius: 50%;
        }

        .Layour-icon-list-icon {
            height: 54px;
            width: 54px;
            border-radius: 5px;
            line-height: 50px;
            text-align: center;
            font-size: 20px;
            background: rgb(231, 231, 231);
        }


    }
}