.Layout-section {
    position: relative;
    min-height: 800px;



    .Layout-section-title {
        font-size: 20px;
        font-weight: bold;
        padding: 10% 10% 2%;

        b {
            float: right;
        }
    }
}