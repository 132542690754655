.Login {
    background: #eee;
    position: fixed;
    display: flex;
    align-items: center;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    justify-content: center;
}

.Login a {
    display: block;
    background: blue;
    color: #fff;
    padding: 20px 30px;
}