.Layout-text {
    .Layout-text-input {
        display: flex;

        input {
            border: none;
            display: block;
            flex: 1;
            line-height: 40px;
            border-radius: 3px;
            outline: none;
            text-indent: 15px;
            font-size: 14px;
            color: #fff;
            background: rgba(0, 0, 0, 0.384);
        }
    }

}