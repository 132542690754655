.Loading {
    padding: 0 12px 0 0;
    background: #fff;
    overflow: hidden;
    background-color: #222;
    z-index: 10000000;
    color: rgb(255, 255, 255);
    font-size: 12px;
    position: relative;
    display: flex;
    border-radius: 4px;
    margin: 0px 0px 2px;
    align-items: center;
    gap: 20px;
    height: 54px;
}

.Loading-detail {

    padding: 8px 14px;
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.141);
    color: #fff;
}

.Loading-title {
    flex: 1;
}

.Loading-bar {
    margin-left: 5px;
}

.Loading-finish .Loading-bar {
    display: none !important;
}

.Loading i {
    display: none;
}

.Loading-finish i {

    font-size: 20px;
    height: 54px;
    background: rgba(255, 255, 255, 0.125);
    line-height: 54px;
    text-align: center;
    border-right: 1px solid rgba(255, 255, 255, 0.125);
    color: rgb(219, 222, 255);
    width: 54px;
    display: block;

}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

.Loading-bar {
    width: 20px;
    margin: 0 0 0 6px;
    height: 20px;
    border: solid 2px #ffffff;
    border-radius: 50%;
    border-right-color: transparent;
    border-bottom-color: transparent;
    -webkit-transition: all 0.5s ease-in;
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 500ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;

    transition: all 0.5s ease-in;
    animation-name: rotate;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}