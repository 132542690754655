.Layout-toggle {
    background: #000;

    &.Layout-toggle-open {
        background: rgb(198, 208, 255);

        .Layout-toggle-open {
            background: rgb(217, 255, 217);

            .Layout-toggle-open {
                background: rgb(255, 238, 205);

                .Layout-toggle-open {
                    background: rgb(117, 255, 126);

                    .Layout-toggle-open {
                        background: rgb(244, 117, 255);

                        .Layout-toggle-open {
                            background: rgb(117, 250, 255);

                            .Layout-toggle-open {
                                background: rgb(255, 117, 122);

                                .Layout-toggle-open {
                                    background: rgb(255, 225, 117);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .Layout-toggle-title {
        cursor: pointer;
    }

    .Layout-toggle-content {
        overflow: hidden;

        .Layout-toggle-content-frame {
            padding: 10px 0px 10px 10px;
            background: rgba(0, 0, 0, 0.4);
            overflow: hidden;
        }
    }
}