.Layout-translation {
    position: relative;
    display: flex;
    margin: 5px;
    border-radius: 5px;
    overflow: hidden;

    .Layout-translation-title {
        background: #000;
        color: #fff;
        width: 50px;
        padding: 10px 12px;
        font-size: 8px;
        text-transform: uppercase;
        text-align: right;
    }

    .Layout-translation-input {
        display: flex;
        flex: 1;

        input {
            border: none;
            display: block;
            flex: 1;
            outline: none;
            padding-left: 12px;
            background: #eee;
        }
    }

}