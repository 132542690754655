.Layout-title {
    border-bottom: 1px solid #aaa;

    display: flex;
    padding: 5px;
    align-items: center;
    gap: 10px;

    background-color: rgba(255, 255, 255, 0.703);

    .Layout-title-bubble {
        background: #000;
        color: rgb(3, 255, 221);
        padding: 5px 10px;
        font-size: 10px;
        text-transform: uppercase;
        border-radius: 4px;
    }

    .Layout-title-space {
        margin-left: auto;
    }

    .Layout-title-icon {
        background: #000;
        height: 40px;
        width: 40px;
        min-width: 40px;
        color: #fff;
        font-size: 20px;
        line-height: 40px;
        text-align: center;
        border-radius: 4px;
    }

    .Layout-title-text {
        color: #000;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100px;
        min-width: 100px;
    }

    .Layout-title-buttons {
        display: flex;

        .Layout-title-buttons-icon {
            width: 40px;
            height: 40px;
            min-width: 40px;
            line-height: 40px;
            text-align: center;
            display: block;
        }

        .Layout-title-buttons-icon-active {
            background: #000;
            color: #fff;
            border-radius: 5px;
        }
    }

}