.Selector ul {
    display: flex;
    margin: 0;
    padding: 0 10px;
    background: rgba(255, 255, 255, 0.434);
    border-bottom: 1px solid rgba(34, 34, 34, 0.255);
    align-items: center;
    padding: 20px;
    padding-left: 20px;

}

.Selector ul li {
    display: flex;
    padding: 0;
    cursor: pointer;
    align-items: center;
    border-radius: calc(var(--radius) - 2px);
    color: rgb(139, 139, 139);
    position: relative;
}

.Selector ul li.active {
    color: #fff;
    font-weight: bold;
    background: rgb(37, 37, 37);


}

.Selector ul li i {

    display: block;
    height: 60px;
    text-align: center;
    width: 60px;
    line-height: 60px;
    font-size: 25px;
}

.Selector ul li b {
    position: absolute;
    height: 14px;
    width: 14px;
    background: rgb(51, 149, 123);
    display: block;
    top: -7px;
    right: -7px;
    border-radius: 10px;
}

.Selector-back {
    float: left;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.072);
    color: rgb(0, 0, 0);
    height: 50px;
    width: 50px;
    border-radius: var(--radius);
    line-height: 50px;
    text-align: center;

}

.Selector-minus {
    float: right;
    margin-left: 20px;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.072);
    color: rgb(0, 0, 0);
    height: 50px;
    width: 50px;
    border-radius: var(--radius);
    line-height: 50px;
    text-align: center;

}

.Selector-minus:hover,
.Selector-back:hover {
    background: #000;
    color: #fff;
}