.Layout-section-components {
    margin-top: 10%;
    border-top: 1px solid #aaa;

    .Layout-add-selector-types {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 10px;
    }

    .Layout-add-selector-type {
        background: rgb(35, 35, 35);
        flex: 1;
        cursor: pointer;
        border-radius: 5px;
        color: #fff;
        margin: 5px;
        width: 100px;
        padding: 10px;
        text-align: center;

        &:hover {
            background: rgb(50, 50, 50);
        }
    }
}