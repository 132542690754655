.Controls {}

.Controls .loader {
    width: 100px;
    background: #000;

    height: 20px;
    background-color: #0004;
    position: relative;
    border-radius: 50px;
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.05);
}

.Controls button {
    background-color: #00000093;
    color: rgba(255, 255, 255, 0.708);
    border: none;
    padding: 20px 0;
    width: 100%;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 12px;
    margin-top: 1px;
}

.Controls .loader:after {
    border-radius: 50px;
    content: "";
    position: absolute;
    background-color: rgb(22, 171, 148);
    left: 2px;
    top: 5px;
    bottom: 5px;
    right: 360px;
    animation: slide 500ms linear infinite;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

@keyframes slide {
    0% {
        right: 50px;
        left: 2px;
    }

    5% {
        left: 2px;
    }

    50% {
        right: 2px;
        left: 50px;
    }

    55% {
        right: 2px;
    }

    100% {
        right: 50px;
        left: 2px;
    }
}