.Layout-input-title {

    padding: 10px 20px;
    border-bottom: 1px solid #aaa;
    position: relative;

    .Layout-input-translation-button {
        right: 0;
        top: 0;
        position: absolute
    }


}